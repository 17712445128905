<template>
  <section>
    <!-- Poner la clase 'match-height' en b-row para hacer que todos los cards de la fila sean del mismo tamaño -->
    <b-skeleton-wrapper :loading="loader.ediciones">
      <template #loading>
        <b-row>
          <b-col
            v-for="item in 4"
            :key="item"
            lg="3"
            md="4"
            sm="6"
            cols="12"
          >
            <b-card no-body>
              <b-card-header>
                <b-skeleton
                  width="100%"
                  height="18em"
                />
              </b-card-header>

              <b-card-body>
                <b-card-title class="mb-0 text-center">
                  <b-skeleton />
                </b-card-title>
              </b-card-body>
              <b-card-footer class="py-50">
                <div class="text-center">
                  <b-row>
                    <b-col cols="6">
                      <b-skeleton
                        type="button"
                        width="100%"
                      />
                    </b-col>
                    <b-col cols="6">
                      <b-skeleton
                        type="button"
                        width="100%"
                      />
                    </b-col>
                  </b-row>
                </div>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col
          v-for="item in itemsToShow"
          :key="item.id_archivo"
          lg="3"
          md="4"
          sm="6"
          cols="12"
        >
          <b-card no-body>
            <b-card-header>
              <img
                v-show="!item.showLoaderImg"
                :src="`https://www.indetec.gob.mx/delivery?srv=0&sl=0&path=${item.rutaDoc}.jpg`"
                :alt="item.publicacion_nombre"
                width="100%"
                @load="item.showLoaderImg = false"
                @error="item.showLoaderImg = false"
              >
              <b-skeleton
                v-if="item.showLoaderImg"
                wanimation="wave"
                width="100%"
                height="18em"
              />
            </b-card-header>

            <b-card-body>
              <b-card-title class="mb-0 text-center">
                {{ nombreEdicion(item) }}
              </b-card-title>
            </b-card-body>
            <b-card-footer class="py-50">
              <div class="text-center">
                <b-button
                  v-if="parseInt(item.preview, 10)"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  class="m-25"
                  :href="`preview/${item.id_archivo}`"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Visualizar</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  class="m-25"
                  :href="`https://www.indetec.gob.mx/delivery?sl=2&srv=0&attachment&path=${item.rutaDoc}${item.extBase}`"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Descargar</span>
                </b-button>
              </div>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-skeleton-wrapper>

    <div
      v-if="isVisibleShowMoreBtn"
      class="text-center mb-2"
    >
      <!-- overlay on button -->
      <b-overlay
        :show="loader.showMore"
        rounded="sm"
        spinner-variant="primary"
        spinner-small
        class="d-inline-block"
      >
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="primary"
          size="sm"
          @click="showMoreItems()"
        >
          Ver más
        </b-button>
      </b-overlay>
    <!-- overlay on button -->
    </div>

  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardFooter,
  BCardBody,
  BSkeletonWrapper,
  BSkeleton,
  BOverlay,
  BButton,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { nombreEdicion } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { edicionesData, edicionesUi } from './useEdiciones'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BSkeletonWrapper,
    BSkeleton,
    BRow,
    BCol,
    BOverlay,
    BButton,
  },
  setup() {
    const toast = useToast()
    const loadMoreLoader = ref(false)
    const { loader, isVisibleShowMoreBtn } = edicionesUi()
    const { showMoreItems, itemsToShow } = edicionesData()

    onMounted(() => store.dispatch('ediciones/fetchItems').then(response => {
      if (response.status !== 200) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Información de solicitud',
            text: response.message,
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        },
        { timeout: 8000 })
      }
    }).catch(err => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Vuelva a intentarlo más tarde',
          text: err,
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      },
      { timeout: 8000 })
    }))

    return {
      loader,
      loadMoreLoader,
      itemsToShow,
      isVisibleShowMoreBtn,

      // Functions
      showMoreItems,

      // Filters
      nombreEdicion,
    }
  },
}
</script>
