<template>
  <section>
    <!-- Header -->
    <ediciones-header />

    <!-- Body -->
    <ediciones-body />
  </section>
</template>

<script>
import EdicionesHeader from './EdicionesHeader.vue'
import EdicionesBody from './EdicionesBody.vue'

export default {
  components: {
    EdicionesHeader,
    EdicionesBody,
  },
}
</script>
