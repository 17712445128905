<template>
  <div v-if="isObject(infogeneral) && !isEmptyObject(infogeneral)">
    <b-card
      :key="infogeneral.avatar"
    >
      <div
        class="d-flex justify-content-start align-items-center m-2"
      >
        <div class="profile-user-info">
          <h2 class="mb-0">
            {{ infogeneral.title }}
          </h2>
        </div>
      </div>
      <b-card-text class="m-2">
        {{ infogeneral.descripcion }}
      </b-card-text>

      <!-- likes comments  share-->
      <b-row class="pb-50 mt-50 ml-2">
        <b-col
          sm="8"
          class="d-flex justify-content-between justify-content-sm-start mb-2"
        >
          <feather-icon
            icon="CheckCircleIcon"
            class="mr-50 text-success"
            size="18"
          />
          <span class="mr-1 text-success">ISSN {{ infogeneral.issn }}</span>
        </b-col>
        <b-col
          sm="4"
          class="d-flex justify-content-between justify-content-sm-end align-items-center mb-2"
        >
          <feather-icon
            icon="BookOpenIcon"
            class="mr-50 text-muted"
            size="18"
          />
          <span class="text-muted mr-1">{{ totalEdiciones }} Publicaciones </span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol,
} from 'bootstrap-vue'
import store from '@/store'
import { computed } from '@vue/composition-api'
import { isObject, isEmptyObject } from '@core/utils/utils'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
  },
  props: {
    infogeneral: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const totalEdiciones = computed(() => store.state.ediciones.fullItems.length)
    return {
      totalEdiciones,
      isObject,
      isEmptyObject,
    }
  },
}
</script>
