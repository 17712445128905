<template>
  <b-card
    v-if="isObject(headerData) && !isEmptyObject(headerData)"
    class="profile-header mb-2"
    :img-src="`https://www.indetec.gob.mx/delivery?srv=0&sl=0&path=/banner_indetec/micrositio-publicaciones/${headerData.coverImg}`"
    img-top
    alt="cover photo"
    body-class="p-0"
  />
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { isObject, isEmptyObject } from '@core/utils/utils'

export default {
  components: {
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    return {
      isObject,
      isEmptyObject,
    }
  },
}
</script>
<style>
.titulocolor{
  background-color: #ffffffbf;
}
.titulocolor h3, .titulocolor p{
  color: #008835;
}
.titulocolor hr {
  border-color: #008835;
}
.titulocolor{
  border-top-left-radius: 5px;
}
@media (max-width: 650px) {
  .titulocolor {
    display: none !important;
  }
}
</style>
