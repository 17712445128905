<template>
  <section>
    <b-row class="content-header">

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="6"
      >
        <b-skeleton-wrapper :loading="loader.ediciones">
          <template #loading>
            <b-skeleton
              width="80%"
              height="2em"
            />
          </template>
          <h2 class="content-header-title float-left pr-1 mr-1 mb-0">
            Ediciones
          </h2>
          <div class="mt-25">
            <b>{{ totalEdicionesShowing }} / {{ totalEdiciones }}</b> resultados disponibles
          </div>
        </b-skeleton-wrapper>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="text-md-right text-sm-center mb-1"
        md="6"
        cols="12"
      >
        <b-dropdown
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :text="sortBy.text"
          right
          :disabled="loader.ediciones"
          variant="primary"
        >
          <b-dropdown-item
            v-for="sortOption in sortByOptions"
            :key="sortOption.value"
            @click="sortBy=sortOption"
          >
            {{ sortOption.text }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import {
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BSkeletonWrapper,
  BSkeleton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { edicionesFiltersSortingAndPagination, edicionesUi } from './useEdiciones'

export default {
  directives: {
    Ripple,
  },
  components: {
    BDropdown,
    BDropdownItem,
    BSkeletonWrapper,
    BSkeleton,
    BRow,
    BCol,
  },
  setup() {
    const {
      totalEdiciones,
      totalEdicionesShowing,
      loader,
    } = edicionesUi()
    const { sortBy, sortByOptions } = edicionesFiltersSortingAndPagination()

    return {
      totalEdiciones,
      totalEdicionesShowing,
      loader,

      sortBy,
      sortByOptions,
    }
  },
}
</script>
