import { computed } from '@vue/composition-api'
import store from '@/store'

export const edicionesData = () => {
  const showMoreItems = () => store.dispatch('ediciones/showMoreItems')
  const itemsToShow = computed(() => store.state.ediciones.itemsToShow)

  return {
    itemsToShow,
    showMoreItems,
  }
}

export const edicionesFiltersSortingAndPagination = () => {
  const sortByOptions = [
    { text: 'Ascendente', value: 'asc' },
    { text: 'Descendente', value: 'desc' },
  ]

  const sortBy = computed({
    get: () => store.state.ediciones.sortBy,
    set: val => {
      store.commit('ediciones/CHANGE_ORDER', val)
    },
  })

  return {
    sortBy,
    sortByOptions,
  }
}

export const edicionesUi = () => {
  const totalEdiciones = computed(() => store.state.ediciones.fullItems.length)
  const totalEdicionesShowing = computed(() => store.state.ediciones.itemsToShow.length)
  const loader = computed(() => store.state.ediciones.loader)
  const isVisibleShowMoreBtn = computed(() => store.state.ediciones.isVisibleShowMoreBtn)

  return {
    totalEdiciones,
    totalEdicionesShowing,
    loader,
    isVisibleShowMoreBtn,
  }
}
