<template>
  <div
    v-if="Object.keys(data).length"
    id="user-profile"
  >
    <profile-header :header-data="infoDatos.header" />
    <!-- publicacion info  -->
    <section id="profile-info">
      <b-row>
        <b-col
          lg="12"
          cols="12"
          order="1"
          order-lg="2"
        >
          <descripcion :infogeneral="infoDatos.infogeneral" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          lg="12"
          cols="12"
          order="1"
          order-lg="2"
        >
          <ediciones />
        </b-col>
      </b-row>
    </section>
    <!--/ publicacion info  -->
  </div>
</template>

<script>

import { BRow, BCol } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { postRequest } from '@core/utils/server-request/general'
import Vue from 'vue'
import Ediciones from './components/ediciones/Ediciones.vue'
import Descripcion from './HomeDescripcion.vue'
import ProfileHeader from './HomeHeader.vue'

/* eslint-disable global-require */
export default {
  components: {
    BRow,
    BCol,
    ProfileHeader,
    Descripcion,
    Ediciones,
  },
  data() {
    return {

      data: {
        header: {
          title: 'FEDERALISMOHACENDARIO',
          subtitle: 'Indetec',
          coverImg: 'fh.png',
        },
      },
    }
  },
  setup() {
    const infoDatos = ref({})
    const toast = useToast()

    const { originConfig } = Vue.config
    const data = new FormData()
    data.append('origen', originConfig.origen)

    postRequest('func-micrositios-publicaciones.php', data)
      .then(response => {
        if (response.status === 200) {
          infoDatos.value = response.data.data
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Información de solicitud',
              text: response.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          },
          { timeout: 8000 })
        }
      })
      .catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Vuelva a intentarlo más tarde',
            text: err,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        },
        { timeout: 8000 })
      })

    return {
      infoDatos,
    }
  },

}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '@core/scss/vue/pages/page-profile.scss';
</style>
